<template>
  <section class="home-standard home-logoband">
    <div class="row">
      <div class="columns text-center">
        <h2>In the Press</h2>
        <div class="logos">
          <img
            v-lazy-load data-src="~assets/images/homepage-logo-businessinsider.png"
            alt="Business Insider Logo"
            data-not-lazy
          />
          <img
            v-lazy-load data-src="~assets/images/homepage-logo-foodwine.svg"
            alt="Food and Wine Logo"
            data-not-lazy
          />
          <img
            v-lazy-load data-src="~assets/images/homepage-logo-forbes.png"
            alt="Forbes Logo"
            data-not-lazy
          />
          <img
            v-lazy-load data-src="~assets/images/homepage-logo-vogue.svg"
            alt="Vogue Logo"
            data-not-lazy
          />
          <img
            v-lazy-load data-src="~assets/images/logo-wineenthusiast.svg"
            alt="Wine Enthusiast Logo"
            data-not-lazy
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HomeLogobanSection',
}
</script>
